import React from "react";
import { Route, Routes } from "react-router-dom";
import type { RouteObject } from "react-router-dom";
import navigation from "./navigation";

const Router = (): JSX.Element => {
  const pageRoutes = navigation.map((route: RouteObject, index: number) => {
    return (
      <Route path={route.path} key={index} element={route.element}>
        {route.children?.map((child: RouteObject, index: number) => {
          return (
            <Route
              path={child.path}
              key={index}
              index={child.index}
              element={child.element}
            />
          );
        })}
      </Route>
    );
  });

  return <Routes>{pageRoutes}</Routes>;
};

export default Router;
