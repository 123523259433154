import React, { lazy } from "react";
import { CircularProgress, PrudentialTheme } from "@pacs-ui/library";
import { Navigate } from "react-router-dom";
import type { RouteObject } from "react-router-dom";
import type { FC } from "react";

const Login = lazy(async () => import("../pages/login"));
const Dashboard = lazy(async () => import("../pages/dashboard"));
const ApplicationForm = lazy(
  async () => import("../pages/dashboard/applicationForm/ApplicationForm")
);
const ProgressForm = lazy(
  async () => import("../pages/dashboard/progressform/ProgressForm")
);
const RegulatoryForm = lazy(
  async () => import("../pages/dashboard/regulatoryform/RegulatoryForm")
);
const SupportingForm = lazy(
  async () => import("../pages/dashboard/supportingform/SupportingForm")
);
// const DeclarationForm = lazy(
//   async () => import("../pages/dashboard/declaration/index")
// );

const PrivateRoute: FC<{ Component: React.FC }> = (props: {
  Component: React.FC;
}) => {
  const authenticated = localStorage.getItem("authenticate");
  const { Component } = props;
  if (authenticated == "SUCCESS") return <Component />;
  return <Navigate to="/login" />;
};
const navigationRoute: RouteObject[] = [
  {
    path: "/",
    element: (
      <React.Suspense
        fallback={
          <CircularProgress sx={{ color: PrudentialTheme.palette.red.main }} />
        }
      >
        <Login />
      </React.Suspense>
    ),
  },
  {
    path: "/login",
    element: (
      <React.Suspense
        fallback={
          <CircularProgress sx={{ color: PrudentialTheme.palette.red.main }} />
        }
      >
        <Login />
      </React.Suspense>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <React.Suspense
        fallback={
          <CircularProgress sx={{ color: PrudentialTheme.palette.red.main }} />
        }
      >
        <PrivateRoute Component={Dashboard} />
      </React.Suspense>
    ),
    children: [
      {
        index: true,
        element: <PrivateRoute Component={ProgressForm} />,
      },
      {
        path: "formProgress",
        element: <PrivateRoute Component={ProgressForm} />,
      },
      {
        path: "appForm/appFormImportantNotes",
        element: <ApplicationForm tabValue={0} />,
      },
      {
        path: "appForm/appFormPersonaldetails",
        element: <ApplicationForm tabValue={1} />,
      },
      {
        path: "appForm/appFormQualifications",
        element: <ApplicationForm tabValue={2} />,
      },
      {
        path: "appForm/appFormOtherInfo",
        element: <ApplicationForm tabValue={3} />,
      },
      {
        path: "appForm/appFormDeclaration",
        element: <ApplicationForm tabValue={4} />,
      },
      {
        path: "regulatoryform",
        element: <PrivateRoute Component={RegulatoryForm} />,
      },
      {
        path: "supportingform",
        element: <PrivateRoute Component={SupportingForm} />,
      },
      // {
      //   path: "appForm/appFormDeclaration",
      //   element: <PrivateRoute Component={DeclarationForm} />,
      // },
    ],
  },
];

export default navigationRoute;