import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { HashRouter } from "react-router-dom";
import Router from "./route/router";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline, PrudentialTheme, ThemeProvider } from "@pacs-ui/library";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={PrudentialTheme}>
      <CssBaseline />
      <HashRouter>
        <QueryClientProvider client={queryClient}>
          <Router />
        </QueryClientProvider>
      </HashRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
